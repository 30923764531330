
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { mapGetters } from 'vuex';
import { std } from 'mathjs'; // eslint-disable-line import/named

import { App } from '../api/src/models/App';
import uiMixin from '../mixins/ui';
import CompanionListItem from './companion-list-item';
import ResourceValueButtonFull from './resource-value-button-full';

export default {
  name: 'ResourceTransform',
  components: {
    CompanionListItem,
    ResourceValueButtonFull,
  },
  mixins: [
    uiMixin,
  ],
  props: {
    name: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      orbitalValues: [],
      isTransforming: false,
      transformedObject: undefined,
      debugStr: '',
    };
  },
  computed: {
    ...mapGetters('companions', [
      'companionActive',
    ]),
    resource() {
      return this.$store.getters['resources/getResourceByName'](this.name);
    },
    countResource() {
      return this.$store.getters[`resources/count${this.name}`]
    },
    label() {
      let label = '';

      if (this.resource) {
        ({ label } = this.resource);
      }

      return label;
    },
    helpText() {
      let helpText = '';

      if (this.resource) {
        ({ helpText } = this.resource);
      }

      return helpText;
    },
    amount() {
      return 1;
    },
    hasResource() {
      return this.amount <= this.countResource;
    },
    orbitalCount() {
      return 2;
    },
  },
  watch: {
    hasResource() {
      if (this.hasResource) {
        this.$nextTick(() => this.initResourceTransformAnim());
      } else {
        this.stopResourceTransformAnim();
      }
    },
  },
  mounted() {
    this.initResourceTransformAnim();
  },
  destroyed() {
    this.stopResourceTransformAnim();
  },
  methods: {
    stopResourceTransformAnim() {
      if (Array.isArray(this._resourceTransformAnims)) {
        this._resourceTransformAnims.forEach((a) => {
          a?.anim?.pause();
          a?.anim?.remove(a?.targets);
        });
      }

      this._resourceTransformAnims = [];
    },
    initResourceTransformAnim() {
      const targets = this.$el.querySelectorAll('.resource-transform-perform-orbit');

      if (targets.length < 1 || targets.length !== this.orbitalCount) {
        return;
      }

      this.stopResourceTransformAnim();

      const props = {
        loop: true,
        easing: 'linear',
      };

      this.$anime.set(targets, {
        translateX: '-50%',
        translateY: '-50%',
      });

      // Every n rotations, the orbs should align
      const durations = [
        [], // n/a -- when there are 0 orbitals
        [ // when there is 1 orbital
          4800,
        ],
        [
          4800,
          2400,
        ],
        [
          4800,
          1600,
          3200,
        ],
        [
          4800,
          3600,
          2400,
          1200,
        ],
      ];

      // length should equal this.orbitalCount
      targets.forEach(($t, i) => {
        const width = `${this.$anime.random(40, 80)}%`;
        const rotateY = this.$anime.random(0, 40);
        const rotateYStart = Math.random() > 0.5 ? -1 * rotateY : rotateY;

        this.$anime.set($t, {
          width,
          height: width,
          rotateY: `${rotateYStart}deg`,
          rotateZ: '0deg',
        });

        const duration = durations[this.orbitalCount][i];

        this._resourceTransformAnims.push({
          targets: $t,
          anim: this.$anime({
            ...props,
            targets: $t,
            duration,
            keyframes: [
              // from start val, to:
              { rotateY: `${-1 * rotateYStart}deg`, rotateZ: '180deg' },
              { rotateY: `${rotateYStart}deg`, rotateZ: '360deg' },
            ],
          }),
        });

        const $orb = $t.querySelector('div');

        this.$anime.set($orb, {
          translateX: '-50%',
          translateY: '50%',
          rotateX: '-80deg', // match css $rotation
        });

        this._resourceTransformAnims.push({
          targets: $t.querySelector('div'),
          anim: this.$anime({
            ...props,
            targets: $t.querySelector('div'),
            duration,
            rotateY: ['0deg', '360deg'],
            update: (anim) => {
              this.$set(this.orbitalValues, i, anim.progress);
            },
          }),
        });

        const $scanlineOrb = this.$el.querySelector(`.resource-transform-perform-scanline[data-index="${i}"] > div`);

        this.$anime.set($scanlineOrb, {
          translateX: '-50%',
        });

        this._resourceTransformAnims.push({
          targets: $scanlineOrb,
          anim: this.$anime({
            ...props,
            targets: $scanlineOrb,
            duration,
            keyframes: [
              // from start val, to:
              // match width of scanline in CSS
              { translateX: '-400%' },
              { translateX: '-50%' },
              { translateX: '400%' },
              { translateX: '-50%' },
            ],
          }),
        });
      });
    },
    getCompanionForArgs(args) {
      let companion = this.$store.getters['companions/defaultCompanion'];
      const rarity = this.$store.getters['companions/getHighestRarityForArgs'](args);

      this.debugStr = Object.keys(args).map(a => `${a}: ${args[a]}`).join(' | ');
      this.debugStr = `rarity: ${rarity} | ${this.debugStr}`;

      const eligibleCompanions = this.$store.getters['companions/getCompanionsOfRarity'](rarity)
        .filter(c => c.test(args));

      if (eligibleCompanions.length > 0) {
        this.$dbg('resource-transform')(`selecting from ${eligibleCompanions.length} ${rarity} companions`);
        companion = eligibleCompanions[Math.floor(Math.random() * eligibleCompanions.length)];
      }

      return companion;
    },
    handleTransform() {
      if (this.hasResource) {
        this.isTransforming = true;
        Haptics.impact({ style: ImpactStyle.Medium });
        this.stopResourceTransformAnim();

        let trueDeviation = 50;
        let distanceFromEdgeDeviation = 50;

        if (Array.isArray(this.orbitalValues) && this.orbitalValues.length === this.orbitalCount) {
          trueDeviation = std(this.orbitalValues);
          const distanceFromEdgeValues = this.orbitalValues.map(v => Math.abs((v % 50) - 25));
          distanceFromEdgeDeviation = std(distanceFromEdgeValues);
        }

        this.orbitalValues = [];

        const args = this.$store.getters['companions/getTestArgs']({
          score: trueDeviation,
          scoreEasy: distanceFromEdgeDeviation,
        });

        const newCompanion = this.getCompanionForArgs(args);

        if (newCompanion) {
          setTimeout(async () => {
            await this.$store.dispatch('userSettings/editResources', [{
              count: this.countResource - this.amount,
              name: this.name,
            }]);

            this.uiNotify(`Transformed ${this.amount} ${this.label}`, {
              logMessage: true,
              icon: this.resource.icon,
            });
            this.uiNotify(this.amount, {
              type: 'ResourceUse',
              payload: {
                resourceName: this.name,
              },
              severity: 'secondary',
            });

            Haptics.vibrate();

            this.transformedObject = {
              ...newCompanion,
              name: newCompanion.name,
              expiresAt: this.$store.getters.virtualTomorrow.toDate(),
            };

            this.isTransforming = false;
          }, 1000);
        } else {
          this.isTransforming = false;
        }
      }
    },
    handleRejectCompanion() {
      this.transformedObject = undefined;
      this.isTransforming = false;
      this.debugStr = '';

      this.$nextTick(() => {
        this.initResourceTransformAnim();
      });
    },
    async handleAcceptCompanion() {
      if (this.transformedObject) {
        let isUsingNew = true;

        if (this.companionActive) {
          isUsingNew = await this.uiMsgBoxConfirm(
            'Are you sure you want to replace your current companion?',
          );
        }

        if (isUsingNew) {
          const companion = { ...this.transformedObject };
          this.transformedObject = undefined;

          const result = await this.$store.dispatch('userSettings/setCompanion', {
            name: companion.name,
            expiresAt: companion.expiresAt,
          });

          if (result) {
            this.uiNotify('Companion changed', {
              logMessage: true,
              conceptName: App.Concept.ConceptName.enum.Companion,
            });

            Haptics.vibrate();
            this.$store.dispatch('ui/showModal', { name: 'companionStatus' });
            this.$emit('close');
          }
        }
      }
    },
  },
};
