
import uiMixin from '../mixins/ui';

export default {
  name: 'ResourceValueButton',
  mixins: [
    uiMixin,
  ],
  props: {
    name: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isTextButton: {
      type: Boolean,
      default: false,
    },
    customCount: {
      type: Number,
      default: undefined,
    },
    useCustomViewHandler: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleView() {
      if (this.$store.getters.isDebugger) {
        if (this.useCustomViewHandler) {
          this.$emit('view');
        } else {
          this.$store.dispatch('ui/showModal', {
            name: 'resourceInfo',
            payload: { name: this.name },
          });
        }
      }
    },
  }
};
