
import _ from 'lodash';
import { Resource } from '../api/src/models/Resource';

export default {
  name: 'ResourceValueButtonFull',
  props: {
    name: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customCount: {
      type: Number,
      default: undefined,
    },
    useCustomViewHandler: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    resource() {
      return Resource.Setting[this.name];
    },
    label() {
      return _.upperFirst(this.resource?.label);
    },
  },
};
