import { render, staticRenderFns } from "./resource-transform.vue?vue&type=template&id=4e3eaef1&"
import script from "./resource-transform.vue?vue&type=script&lang=js&"
export * from "./resource-transform.vue?vue&type=script&lang=js&"
import style0 from "./resource-transform.vue?vue&type=style&index=0&id=4e3eaef1&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ResourceValueButtonFull: require('/opt/build/repo/components/resource-value-button-full.vue').default,AppConcept: require('/opt/build/repo/components/app-concept.vue').default,CompanionListItem: require('/opt/build/repo/components/companion-list-item.vue').default,ResourceValue: require('/opt/build/repo/components/resource-value.vue').default})
